import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import EditableInput from "../input/EditableInput";
import {
  addFollowUpSurveyEntry,
  addSurveyEntry,
  addTargetValue,
  createActivity, deleteActivity, deleteEntryFromFollowUpSurvey,
  deleteEntryFromSurvey, deleteTargetValue,
  getActivities, getActivityDump, searchLocations, sendActivityReminder,
  updateActivity
} from "../../utils/api";
import {formatTime} from '../../utils/timeHelper';
import {
  formatActivityType,
  getInTextActivityType
} from "../../utils/activityTypeHelper";
import {truncate} from "../../utils/stringHelper";
import debounce from 'lodash.debounce';
import {useInView} from "react-cool-inview";
import {makeStyles, withStyles} from "@mui/styles";
import {
  Tooltip, Typography, Table, TableCell, TableHead,
  TableRow, ImageList, AppBar, Chip, Collapse, MenuItem, TableBody, IconButton,
  Select, Paper, Toolbar, TableContainer, Radio, RadioGroup, FormControl,
  FormControlLabel, Autocomplete, TextField, CircularProgress, Fab, FormGroup, Checkbox
} from "@mui/material";
import ConfirmActionDialog from "../dialogs/ConfirmActionDialog";
import AddActivityDialog from "../dialogs/AddActivityDialog";
import AddTargetValueDialog from "../dialogs/AddTargetValueDialog";
import {DescriptionList, DescriptionTerm, Description} from "../parts/Description.parts";
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import DownloadIcon from "@mui/icons-material/GetApp";
import {UserContext} from "../auth/UserProvider";
import * as XLSX from "xlsx";
import DumpLoadingIndicator from "../parts/DumpLoadingIndicator";
import IncompleteIcon from "@mui/icons-material/Cancel";
import CompleteIcon from "@mui/icons-material/Verified";
import ConfirmRemindUserDialog from "../dialogs/ConfirmRemindUserDialog";

const styles = () => ({
  contentWrapper: {
    margin: 'auto',
    marginTop: '2rem',
    marginLeft: '2rem',
  },
  tableContainer: {
    margin: 'auto',
    marginTop: '2rem',
    marginLeft: '2rem',
    overflow: 'hidden',
  },
  tableHead: {
    background: '#F5F5F5',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  gridList: {
    paddingBottom: '1rem',
  },
  paper: {
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

function Row(props) {
  let { activity, fetchData, shouldOpen, removeHandler, state, obsrv,
    isAppUser = false, isSuperUser = false, isReadOnlyUser = false } = props;
  const [open, setOpen] = useState(shouldOpen);
  const classes = useRowStyles();
  const cancelRequest = useRef();
  const [distributionValue, setDistributionValue] = React.useState(1);
  const [improvementValue, setImprovementValue] = React.useState(1);
  const [stateActivity, setStateActivity] = useState(activity);
  const [activityBuffer, setActivityBuffer] = useState(activity);

  const outputs = [
    { name: 'Output 1', value: 'OUTPUT_1', description: '- Les capacités des acteurs en vue de mettre en œuvre des mesures visant à promouvoir '
          + 'la durabilité environnementale, économique et sociale dans et autour '
          + 'du secteur minier sont améliorées.' },
    { name: 'Output 2', value: 'OUTPUT_2', description: '- Les provinces du Haut-Katanga et du Lualaba ont progressé dans la création de sources'
          + ' de revenus respectueuses de l’environnement et des droits humains pour'
          + ' les mineur·euse·s artisa-naux·ales.' },
    { name: 'Output 3', value: 'OUTPUT_3', description: '- Les apprenti·e·s et les personnes intéressées par le travail indépendant des communautés'
          + ' minières du Haut-Katanga et du Lualaba utilisent des offres de qualification'
          + ' améliorées et axées sur la pratique.' },
    { name: 'Output 4', value: 'OUTPUT_4', description: '- Les capacités d’un recouvrement des impôts plus efficace des entreprises minières'
          + ' ont été améliorées au sein de l’administration fiscale congolaise.' },
  ];

  const setReviewedConfirmationHandler = async () => {
    await updateActivityDataAndDispatch('reviewed', true);
    await fetchData(true);
  };

  const remindUserConfirmationHandler = async (activityId) => {
    await sendActivityReminder({ activityId: activityId });
  };

  const restoreConfirmationHandler = async () => {
    await updateActivityDataAndDispatch('deleted', false);
    await fetchData(true);
  };

  const handleImprovementRadioChange = (event) => {
    updateActivityDataAndDispatch('improvement', event.target.value);
    setImprovementValue(event.target.value);
  };

  const handleDistributionRadioChange = (event) => {
    updateActivityDataAndDispatch('distribution', event.target.value);
    setDistributionValue(event.target.value);
  };

  const handleOutputValueChange = (event, values) => {
    updateActivityDataAndDispatch('outputs', values);
  };

  const handleCompletionStatusChange = (status) => {
    updateActivityDataAndDispatch('completed', status);
  };

  const addTargetValueConfirmationHandler =
      async (numericValue,
          type) => {
        await addTargetValue(activity.id, {
          'numericValue': +numericValue,
          'type': type !== '' ? type : null,
        });
        await fetchData(true);
      };

  const addSurveyEntryConfirmationHandler =
      async (gender, age, newCompetencies,
          newCompetenciesPractical, newCompetenciesColleagues,
          activityRating, comment) => {
        await addSurveyEntry(activity.id, {
          'gender': gender !== '' ? gender : null,
          'age': +age > 0 ? +age : null,
          'newCompetencies': newCompetencies !== '' ? newCompetencies : null,
          'newCompetenciesPractical': newCompetenciesPractical !== '' ? newCompetenciesPractical : null,
          'newCompetenciesColleagues': newCompetenciesColleagues !== '' ? newCompetenciesColleagues : null,
          'activityRating': activityRating !== '' ? activityRating : null,
          'comment': comment !== '' ? comment : null,
        });
        await fetchData(true);
      };

  const addFollowUpSurveyEntryConfirmationHandler =
      async (gender, age, newCompetenciesPractical, newCompetenciesColleagues,
          additionalQuestion, additionalQuestionAnswer, comment) => {
        await addFollowUpSurveyEntry(activity.id, {
          'gender': gender !== '' ? gender : null,
          'age': +age > 0 ? +age : null,
          'newCompetenciesPractical': newCompetenciesPractical !== '' ? newCompetenciesPractical : null,
          'newCompetenciesColleagues': newCompetenciesColleagues !== '' ? newCompetenciesColleagues : null,
          'additionalQuestion': additionalQuestion !== '' ? additionalQuestion : null,
          'additionalQuestionAnswer': additionalQuestionAnswer !== '' ? additionalQuestionAnswer === 'YES' : null,
          'comment': comment !== '' ? comment : null,
        });
        await fetchData(true);
      };

  const updateActivityDataAndDispatch = async (field, value) => {
    try {
      setActivityBuffer({
        ...activityBuffer,
        [field]: value,
      });
      const { data: activityData } = await updateActivity(
          activity.id,
          { [field]: value }
      );

      if (!cancelRequest.current) {
        setStateActivity(activityData);
      }
    } catch (error) {
      setActivityBuffer(activityBuffer);
    }
  };

  const removeTargetValue = async (activityId, targetValueId) => {
    await deleteTargetValue(activityId, targetValueId);
    await fetchData(true);
  };

  const removeEntryFromSurvey = async (activityId, entryId) => {
    await deleteEntryFromSurvey(activityId, entryId);
    await fetchData(true);
  };

  const removeEntryFromFollowUpSurvey = async (activityId, entryId) => {
    await deleteEntryFromFollowUpSurvey(activityId, entryId);
    await fetchData(true);
  };

  const handleActivityTypeChange = async (event) => {
    await updateActivityDataAndDispatch('activityType', event.target.value);
    await fetchData(true);
  };

  const getLockQuestion = () => {
    var i;
    var lockQuestion = '';

    if (activity.followUpSurvey && activity.followUpSurvey.surveyEntries) {
      const surveyEntries = activity.followUpSurvey.surveyEntries;

      for (i = 0; i < surveyEntries.length; i++) {
        if (surveyEntries[i].additionalQuestion &&
            surveyEntries[i].additionalQuestion !== '') {
          lockQuestion = surveyEntries[i].additionalQuestion;
          break;
        }
      }
    }

    return lockQuestion;
  };

  return (
      <React.Fragment>
        <TableRow ref={obsrv} className={classes.root}>
          <TableCell style={{ paddingBottom: 0, border: "none" }} colSpan={7}>
            <strong>{stateActivity.name}</strong>
          </TableCell>
        </TableRow>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{stateActivity.activityType !== 'OTHER' ? formatActivityType(stateActivity.activityType) : stateActivity.otherTypeName }</TableCell>
          <TableCell>{formatTime(stateActivity.date, 'dd.LL.yyyy')}</TableCell>
          <TableCell>{stateActivity.location ? stateActivity.location.englishName : ''}</TableCell>
          <TableCell>
            {stateActivity.outputs.map((output, idx) => (
                <Chip key={idx}  size="small" style={{marginBottom: '0.2rem', marginRight: '0.8rem'}} label={output.name} />
            ))}
          </TableCell>
          <TableCell>
            {stateActivity.indicators && stateActivity.indicators.map((indicator) => (
                <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit">{indicator.name}</Typography>
                        {indicator.description}
                      </React.Fragment>
                    }
                >
                  <Chip size="small" style={{marginBottom: '0.2rem', marginRight: '0.8rem'}} label={truncate(indicator.name, 30)} />
                </HtmlTooltip>
            ))}
          </TableCell>
          <TableCell>
            { stateActivity.completed ?
              <Tooltip title="Activité révisée">
                <CompleteIcon sx={{margin: '16px 16px', color: 'green'}} />
              </Tooltip>
              :
              <Tooltip title="Activité non révisée">
                <IncompleteIcon  sx={{margin: '16px 16px', color: 'orange'}} />
              </Tooltip>
           }
          </TableCell>
          <TableCell width={'15%'}>
            { state !== 'DELETED' && !isReadOnlyUser &&
              <ConfirmActionDialog action={'delete'} confirmationHandler={() => removeHandler(stateActivity.id)}/>
            }
            { state === 'NEW' && !isAppUser && !isReadOnlyUser &&
              <>
                <ConfirmRemindUserDialog activityId={activity.id} confirmationHandler={remindUserConfirmationHandler} />
                <ConfirmActionDialog action={'reviewed'} confirmationHandler={setReviewedConfirmationHandler}/>
              </>
            }
            { state === 'DELETED' && !isReadOnlyUser &&
              <>
                <ConfirmActionDialog action={'restore'} confirmationHandler={restoreConfirmationHandler}/>
                <ConfirmActionDialog action={'harddelete'} confirmationHandler={() => removeHandler(stateActivity.id, 'HARD')}/>
              </>
            }
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse style={{marginBottom: '1rem'}} in={open} timeout="auto" unmountOnExit>
              <Typography variant="h5" gutterBottom component="div">
                Détails de l'activité
              </Typography>
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      1. Informations générales
                    </Typography>
                  </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                  <ImageList cellHeight={'auto'} className={classes.gridList} cols={2}>
                  <DescriptionList>
                    <DescriptionTerm>Type</DescriptionTerm>
                    <Description>
                      <Select
                          labelId="activity-type-select"
                          id="activity-type-select"
                          value={stateActivity.activityType}
                          className={classes.selectEmpty}
                          onChange={handleActivityTypeChange}
                      >
                        <MenuItem value={'SEMINAR'}>Séminaire / Formation</MenuItem>
                        <MenuItem value={'WORKSHOP'}>Atelier / Workshop</MenuItem>
                        <MenuItem value={'PUBLICATION'}>Publication</MenuItem>
                        <MenuItem value={'CAMPAIGN'}>Campagne médiatique</MenuItem>
                        <MenuItem value={'OTHER'}>Autre</MenuItem>
                      </Select>
                    </Description>
                  </DescriptionList>
                  {stateActivity.activityType === 'OTHER' &&
                    <DescriptionList>
                      <DescriptionTerm>Autre type</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="otherTypeName"
                            value={stateActivity.otherTypeName}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            onSave={(fieldName,
                                value) => updateActivityDataAndDispatch(fieldName,
                                value)}
                        />
                      </Description>
                    </DescriptionList>
                  }
                  </ImageList>
                  <ImageList cellHeight={'auto'} className={classes.gridList} cols={2}>
                    <DescriptionList>
                      <DescriptionTerm>Nom</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="name"
                            value={stateActivity.name}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            multiline={true}
                            rows={4}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Lieu</DescriptionTerm>
                      <Description>
                        <BasicAutocomplete handleChange={(value) => updateActivityDataAndDispatch('location', value)} searchFct={searchLocations} currentValue={stateActivity.location} property={'englishName'} />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Date de début</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="date"
                            type="date"
                            value={formatTime(stateActivity.date, 'dd.LL.yyyy')}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value + ' 00:00:00.000')}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Date de fin</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="endDate"
                            type="date"
                            value={formatTime(stateActivity.endDate, 'dd.LL.yyyy')}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value + ' 00:00:00.000')}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Objectif</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="objective"
                            value={stateActivity.objective}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Commentaires</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="comment"
                            value={stateActivity.comment}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <FormGroup>
                        <FormControlLabel
                            sx={{ alignItems: 'flex-start', marginTop: 2 }}
                            control={
                              <Checkbox
                                  checked={stateActivity.completed}
                                  color="primary"
                                  sx={{
                                    marginTop: -1.6,
                                  }}
                                  onChange={(e) => {
                                    let checked = e.target.checked;
                                    handleCompletionStatusChange(checked);
                                  }
                                  } />}
                            label="Activité réalisée" />
                      </FormGroup>
                    </DescriptionList>
                  </ImageList>
                </div>
              </Paper>
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      2. Cibles prévues
                    </Typography>
                  </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                  <TableContainer style={{width: '95%'}}>
                    <Table size="small">
                      <TableHead className={classes.tableHead}>
                        <TableRow>
                          <TableCell width={'10%'}>Nombre</TableCell>
                          <TableCell width={'30%'}>Type</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {activity.targetValues.map((targetValue) => (
                            <TableRow key={targetValue.id}>
                              <TableCell width={'10%'}>{targetValue.numericValue}</TableCell>
                              <TableCell width={'30%'}>{targetValue.type}</TableCell>
                              <TableCell>
                                <Tooltip title="Supprimer">
                                  <IconButton onClick={() => removeTargetValue(activity.id, targetValue.id)}>
                                    <DeleteIcon className={classes.block} color="inherit" />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  { !isReadOnlyUser &&
                    <div className={classes.contentWrapper}>
                      <AddTargetValueDialog confirmationHandler={addTargetValueConfirmationHandler}/>
                    </div>
                  }
                </div>
              </Paper>
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    {stateActivity.activityType !== 'PUBLICATION'
                    && stateActivity.activityType !== 'CAMPAIGN' &&
                    <Typography variant="h6" gutterBottom component="div">
                      3. Informations sur les participants
                    </Typography>
                    }
                    {stateActivity.activityType === 'PUBLICATION' &&
                    <Typography variant="h6" gutterBottom component="div">
                      3. Informations sur la publication
                    </Typography>
                    }
                    {stateActivity.activityType === 'CAMPAIGN' &&
                    <Typography variant="h6" gutterBottom component="div">
                      3. Informations sur la campagne
                    </Typography>
                    }
                  </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                  {stateActivity.activityType !== 'PUBLICATION' && stateActivity.activityType !== 'CAMPAIGN' &&
                  <ImageList cellHeight={'auto'} className={classes.gridList} cols={3}>
                    <DescriptionList>
                      <DescriptionTerm>Nombre total de participants</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="numberParticipants"
                            value={stateActivity.numberParticipants}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Dont nombre de femmes</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="numberFemaleParticipants"
                            value={stateActivity.numberFemaleParticipants}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Dont nombre de jeunes (estimation: 15-30 ans)</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="numberYoungParticipants"
                            value={stateActivity.numberYoungParticipants}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Facilitateur</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="moderator"
                            value={stateActivity.moderator}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Organisateur</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="organizer"
                            value={stateActivity.organizer}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                  </ImageList>
                  }
                  { stateActivity.activityType === 'PUBLICATION' &&
                  <ImageList cellHeight={'auto'} className={classes.gridList} cols={3}>
                    <DescriptionList>
                      <DescriptionTerm>Auteur</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="author"
                            value={stateActivity.author}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Publié par</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="publisher"
                            value={stateActivity.publisher}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Nombre d'impressions</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="numberOfViews"
                            value={stateActivity.numberOfViews}
                            type="number"
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                  </ImageList>
                  }
                  { stateActivity.activityType === 'CAMPAIGN' &&
                  <ImageList cellHeight={'auto'} className={classes.gridList} cols={3}>
                    <DescriptionList>
                      <DescriptionTerm>Mise en œuvre par</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="implementor"
                            value={stateActivity.implementor}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName,
                                value) => updateActivityDataAndDispatch(
                                fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Diffusée par</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="distributor"
                            value={stateActivity.distributor}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName,
                                value) => updateActivityDataAndDispatch(fieldName,
                                value)}
                        />
                      </Description>
                    </DescriptionList>
                  </ImageList>
                  }
                </div>
              </Paper>
              { stateActivity.activityType !== 'CAMPAIGN' &&
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      4. Évaluation
                    </Typography>
                  </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                  { stateActivity.activityType !== 'CAMPAIGN' &&
                  <>
                    <Typography variant="p" gutterBottom component="div">
                      Portée de l'activité
                    </Typography>
                    <FormControl component="fieldset">
                      <RadioGroup row={true} aria-label="distribution" name="distribution" value={stateActivity.distribution} onChange={handleDistributionRadioChange}>
                        <FormControlLabel value="STRONG" control={<Radio color="primary" />} label="forte" />
                        <FormControlLabel value="MEDIUM" control={<Radio color="primary" />} label="moyennne" />
                        <FormControlLabel value="WEAK" control={<Radio color="primary" />} label="faible" />
                      </RadioGroup>
                    </FormControl>
                  </>
                  }
                  { stateActivity.activityType !== 'CAMPAIGN' && stateActivity.activityType !== 'PUBLICATION' &&
                  <>
                    <Typography variant="p" gutterBottom component="div">
                      Votre {getInTextActivityType(stateActivity.activityType)} a-t-il présenté des résultats imprévus ?
                    </Typography>
                    <FormControl component="fieldset">
                      <RadioGroup row={true} aria-label="improvement" name="improvement" value={stateActivity.improvement} onChange={handleImprovementRadioChange}>
                        <FormControlLabel value="YES" control={<Radio color="primary" />} label="Oui" />
                        <FormControlLabel value="NO" control={<Radio color="primary" />} label="Non" />
                      </RadioGroup>
                    </FormControl>
                    {stateActivity.improvement === 'YES' &&
                    <>
                      <DescriptionList>
                        <DescriptionTerm>Si oui, lesquels ?</DescriptionTerm>
                        <Description>
                          <EditableInput
                              name="improvementDescription"
                              value={stateActivity.improvementDescription}
                              disabled={false}
                              fullWidth={true}
                              onSave={(fieldName,
                                  value) => updateActivityDataAndDispatch(fieldName,
                                  value)}
                          />
                        </Description>
                      </DescriptionList>
                      <br/>
                    </>
                    }
                  </>
                  }
                </div>
              </Paper>
              }
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      5. Attribution aux Outputs
                    </Typography>
                  </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                  <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={outputs}
                      getOptionLabel={(option) => option.name}
                      filterSelectedOptions
                      value={stateActivity.outputs}
                      onChange={handleOutputValueChange}
                      renderOption={(props, option) => (
                          <li {...props}>{option.name} {option.description}</li>
                      )}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              variant="outlined"
                              label="Outputs auxquels cette activité contribue"
                          />
                      )}
                  />
                </div>
              </Paper>
              {/*<Paper elevation={0} variant="outlined" className={classes.paper}>*/}
              {/*  <AppBar className={classes.toolbar}*/}
              {/*          position="static"*/}
              {/*          color="default"*/}
              {/*          elevation={0}>*/}
              {/*    <Toolbar>*/}
              {/*      <Typography variant="h6" gutterBottom component="div">*/}
              {/*        6. Sondage*/}
              {/*      </Typography>*/}
              {/*    </Toolbar>*/}
              {/*  </AppBar>*/}
              {/*  <div className={classes.contentWrapper}>*/}
              {/*    <TableContainer className={classes.tableContainer}>*/}
              {/*      <Table size="small" aria-label="collapsible table">*/}
              {/*        <TableHead>*/}
              {/*          <TableRow>*/}
              {/*            <TableCell width={'10%'}>Genre</TableCell>*/}
              {/*            <TableCell width={'10%'}>Age</TableCell>*/}
              {/*            <TableCell width={'10%'}>Nouvelles compétences</TableCell>*/}
              {/*            <TableCell width={'10%'}>Mise en pratique</TableCell>*/}
              {/*            <TableCell width={'10%'}>Transfert des compétences</TableCell>*/}
              {/*            <TableCell width={'10%'}>Déroulement</TableCell>*/}
              {/*            <TableCell width={'35%'}>Commentaires</TableCell>*/}
              {/*            <TableCell width={'5%'}>Action</TableCell>*/}
              {/*          </TableRow>*/}
              {/*        </TableHead>*/}
              {/*        <TableBody>*/}
              {/*          {activity.survey && activity.survey.surveyEntries.map((entry) => (*/}
              {/*              <TableRow key={entry.id}>*/}
              {/*                <TableCell width={'10%'}>{formatGender(entry.gender)}</TableCell>*/}
              {/*                <TableCell width={'10%'}>{entry.age}</TableCell>*/}
              {/*                <TableCell width={'10%'}>{formatAgreeType(entry.newCompetencies)}</TableCell>*/}
              {/*                <TableCell width={'10%'}>{formatAgreeType(entry.newCompetenciesPractical)}</TableCell>*/}
              {/*                <TableCell width={'10%'}>{formatAgreeType(entry.newCompetenciesColleagues)}</TableCell>*/}
              {/*                <TableCell width={'10%'}>{formatAgreeType(entry.activityRating)}</TableCell>*/}
              {/*                <TableCell width={'35%'}>{entry.comment}</TableCell>*/}
              {/*                <TableCell width={'5%'}>*/}
              {/*                  <Tooltip title="Supprimer">*/}
              {/*                    <IconButton onClick={() => removeEntryFromSurvey(activity.id, entry.id)}>*/}
              {/*                      <DeleteIcon className={classes.block} color="inherit" />*/}
              {/*                    </IconButton>*/}
              {/*                  </Tooltip>*/}
              {/*                </TableCell>*/}
              {/*              </TableRow>*/}
              {/*          ))}*/}
              {/*        </TableBody>*/}
              {/*      </Table>*/}
              {/*    </TableContainer>*/}
              {/*    <div className={classes.contentWrapper}>*/}
              {/*      {!isReadOnlyUser &&*/}
              {/*      <AddSurveyEntryDialog*/}
              {/*          confirmationHandler={addSurveyEntryConfirmationHandler}/>*/}
              {/*      }*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</Paper>*/}
              {/*<Paper elevation={0} variant="outlined" className={classes.paper}>*/}
              {/*  <AppBar className={classes.toolbar}*/}
              {/*          position="static"*/}
              {/*          color="default"*/}
              {/*          elevation={0}>*/}
              {/*    <Toolbar>*/}
              {/*      <Typography variant="h6" gutterBottom component="div">*/}
              {/*        7. Sondage de suivi*/}
              {/*      </Typography>*/}
              {/*    </Toolbar>*/}
              {/*  </AppBar>*/}
              {/*  <div className={classes.contentWrapper}>*/}
              {/*    <TableContainer className={classes.tableContainer}>*/}
              {/*      <Table size="small" aria-label="collapsible table">*/}
              {/*        <TableHead>*/}
              {/*          <TableRow>*/}
              {/*            <TableCell width={'10%'}>Genre</TableCell>*/}
              {/*            <TableCell width={'10%'}>Age</TableCell>*/}
              {/*            <TableCell width={'20%'}>Mise en pratique</TableCell>*/}
              {/*            <TableCell width={'20%'}>Transfert des compétences</TableCell>*/}
              {/*            <TableCell width={'20%'}>*/}
              {/*              {activity.followUpSurvey && activity.followUpSurvey.surveyEntries && activity.followUpSurvey.surveyEntries[0] &&*/}
              {/*              <>*/}
              {/*                {formatAdditionalQuestionHeader(activity.followUpSurvey.surveyEntries)}*/}
              {/*              </>*/}
              {/*              }*/}
              {/*            </TableCell>*/}
              {/*            <TableCell width={'15%'}>Commentaires</TableCell>*/}
              {/*            <TableCell width={'5%'}>Action</TableCell>*/}
              {/*          </TableRow>*/}
              {/*        </TableHead>*/}
              {/*        <TableBody>*/}
              {/*          {activity.followUpSurvey && activity.followUpSurvey.surveyEntries.map((entry) => (*/}
              {/*              <TableRow key={entry.id}>*/}
              {/*                <TableCell width={'10%'}>{formatGender(entry.gender)}</TableCell>*/}
              {/*                <TableCell width={'10%'}>{entry.age}</TableCell>*/}
              {/*                <TableCell width={'20%'}>{formatAgreeType(entry.newCompetenciesPractical)}</TableCell>*/}
              {/*                <TableCell width={'20%'}>{formatAgreeType(entry.newCompetenciesColleagues)}</TableCell>*/}
              {/*                <TableCell width={'20%'}>{formatAdditionalQuestionAnswer(entry.additionalQuestionAnswer)}</TableCell>*/}
              {/*                <TableCell width={'35%'}>{entry.comment}</TableCell>*/}
              {/*                <TableCell width={'5%'}>*/}
              {/*                  <Tooltip title="Supprimer">*/}
              {/*                    <IconButton onClick={() => removeEntryFromFollowUpSurvey(activity.id, entry.id)}>*/}
              {/*                      <DeleteIcon className={classes.block} color="inherit" />*/}
              {/*                    </IconButton>*/}
              {/*                  </Tooltip>*/}
              {/*                </TableCell>*/}
              {/*              </TableRow>*/}
              {/*          ))}*/}
              {/*        </TableBody>*/}
              {/*      </Table>*/}
              {/*    </TableContainer>*/}
              {/*    <div className={classes.contentWrapper}>*/}
              {/*      {!isReadOnlyUser &&*/}
              {/*      <AddFollowUpSurveyEntryDialog*/}
              {/*          confirmationHandler={addFollowUpSurveyEntryConfirmationHandler}*/}
              {/*          lockQuestion={getLockQuestion}/>*/}
              {/*      }*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</Paper>*/}
              { isSuperUser &&
                <Paper elevation={0} variant="outlined" className={classes.paper}>
                  <AppBar className={classes.toolbar}
                          position="static"
                          color="default"
                          elevation={0}>
                    <Toolbar>
                      <Typography variant="h6" gutterBottom component="div">
                        6. Information sur l’émetteur
                      </Typography>
                    </Toolbar>
                  </AppBar>
                  <div className={classes.contentWrapper}>
                    <ImageList cellHeight={'auto'} className={classes.gridList} cols={3}>
                      <DescriptionList>
                        <DescriptionTerm>Adresse e-mail</DescriptionTerm>
                        <Description>
                          <EditableInput
                              disabled
                              name="createdByEmail"
                              value={stateActivity.createdByEmail}
                              fullWidth={true}
                          />
                        </Description>
                      </DescriptionList>
                    </ImageList>
                  </div>
                </Paper>
              }
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
  );
}

function Activities(props) {
  const { classes, state } = props;
  const [items, setItems] = useState([]);
  const pageToLoad = useRef(0);
  const initialPageLoaded = useRef(false);
  const [hasMore, setHasMore] = useState(true);
  const [expanded, setExpanded] = React.useState('panel1');
  const [isLoadingDump, setIsLoadingDump] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const didMount = useRef(false);

  const authState = useContext(UserContext);

  const { observe } = useInView({
    // For better UX, we can grow the root margin so the data will be loaded earlier
    rootMargin: "50px 0px",
    // When the last item comes to the viewport
    onEnter: ({ unobserve, observe }) => {
      if (hasMore) {
        // Pause observe when loading data
        unobserve();
        // Load more data
        loadItems(false).then(observe());

        initialPageLoaded.current = true;
        pageToLoad.current = Number(pageToLoad.current) + 1;
      }

    },
  });

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const downloadActivityDump = (data) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push('Liste des activites');

    let ws_data = [
      ['Id', 'Type', 'Nom', 'Lieu', 'Date de début', 'Date de fin',
        'Objectif', 'Commentaires', 'Nombre total de participants',
      'Dont nombre de femmes', 'Dont nombre de jeunes', 'Facilitateur',
        'Organisateur', 'Attribution à l’indicateur / aux indicateurs', 'Attribution aux Outputs', 'Cibles prévues'],
    ];

    data.map(a => {
      ws_data.push([a.id, formatActivityType(a.activityType), a.name,
        a.location ? a.location.englishName : '', formatTime(a.date),
        formatTime(a.endDate), a.objective, a.comment, a.numberParticipants,
        a.numberFemaleParticipants, a.numberYoungParticipants, a.moderator,
        a.organizer, a.indicators ? a.indicators.map(i => i.name).join(' / ') : '',
        a.outputs ? a.outputs.map(i => i.name).join(' / ') : '',
        a.targetValues ? a.targetValues.map(i => `${i.numericValue + ' ' + i.type}`).join(' / ') : '']);
    });

    wb.Sheets['Liste des activites'] = XLSX.utils.aoa_to_sheet(
        ws_data);

    let date = new Date();

    XLSX.writeFile(wb,'Liste_des_activités.xlsx');
  };

  const fetchDump =
      async () => {
        setIsError(false);
        setIsLoadingDump(true);
        try {
          await getActivityDump()
          .then((response) => {
            setIsLoadingDump(false);
            if (response.data) {
              downloadActivityDump(response.data);
            }
          });
        } catch (error) {
          console.log(error);
        }
      };

  const loadItems = async (reset = false) => {
    const data = await getActivities(state, searchTerm, reset ? 0 : pageToLoad.current);
    setHasMore(data.data.totalPages > pageToLoad.current);
    setItems(reset ? data.data.content : prevItems => [...prevItems, ...data.data.content].reduce((unique, o) => {
      if(!unique.some(obj => obj.id === o.id)) {
        unique.push(o);
      }
      return unique;
    },[]));

    if (reset) {
      pageToLoad.current = 0;
    }
  };

  const removeActivity = async (activityId, deleteType = 'SOFT') => {
    await deleteActivity(activityId, deleteType);
    await loadItems(true);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSearchTermChanged = (event) => {
    debouncedSetSearchTerm(event.target.value);
  };

  const addActivityConfirmationHandler =
      async (activityType) => {
        await createActivity({
          'activityType': activityType,
        });
        await loadItems(true);
      };

  useEffect(() => {
    if (initialPageLoaded.current) {
      return;
    }

    loadItems(false);

    initialPageLoaded.current = true;
    pageToLoad.current = Number(pageToLoad.current) + 1;
  }, [loadItems]);

  useEffect(() => {
    if (didMount.current) {
      loadItems(true);
    }
    else didMount.current = true;
  }, [searchTerm]);

  const renderData = () => {
    if (isError) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            { state !== 'DELETED' &&
            <Typography variant="h6" color="primary" style={{marginTop: '10%'}}>
              Essayez d'ajouter une nouvelle activité!
            </Typography>
            }
            { state === 'DELETED' &&
            <Typography variant="h6" color="primary" style={{marginTop: '10%'}}>
              La corbeille est vide!
            </Typography>
            }
            { state === 'NEW' && authState.role !== 'ROLE_READER' &&
            <AddActivityDialog classes={classes} confirmationHandler={addActivityConfirmationHandler}/>
            }
          </div>
      );
    }

    if (isLoading || !items) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            <CircularProgress style={{marginTop: '10%'}} color="primary" />
          </div>
      );
    }

    if (isLoadingDump) {
      return (
          <DumpLoadingIndicator />
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{width: '95%'}}>
            <TextField
                label="Nom, type, lieu, date, output (par ex. 'Output 1'), email de l’émetteur"
                id="activity-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <TableContainer style={{width: '95%'}} component={Paper} className={classes.tableContainer}>
            <Table aria-label="collapsible table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell />
                  <TableCell>Type</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Lieu</TableCell>
                  <TableCell>Outputs</TableCell>
                  <TableCell>Attribution à l’indicateur / aux indicateurs</TableCell>
                  <TableCell>Statut</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i).map((row, idx) => (
                    <Row
                        key={row.id}
                        activity={row}
                        fetchData={loadItems}
                        shouldOpen={items.length === 1}
                        removeHandler={removeActivity}
                        state={state}
                        obsrv={idx === items.length - 1 ? observe : null}
                        isAppUser={authState.role === 'ROLE_APP'}
                        isSuperUser={authState.role === 'ROLE_SUPER'}
                        isReadOnlyUser={authState.role === 'ROLE_READER'}
                    />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          { state === 'REVIEWED' &&
            <div hidden={authState && authState.role && authState.role !== 'ROLE_SUPER'}>
              <Fab
                  size="small"
                  color="primary"
                  aria-label="add"
                  onClick={() => fetchDump()}
                  className={classes.fab}
                  style={{position: 'fixed', marginBottom: '1rem'}}
              >
                <DownloadIcon/>
              </Fab>
            </div>
          }
          { state === 'NEW' && authState.role !== 'ROLE_READER' &&
          <AddActivityDialog classes={classes} confirmationHandler={addActivityConfirmationHandler}/>
          }
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(Activities);